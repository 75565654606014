// console.log only when env is test or mock

import { getEnv } from './env';

export function devlog(...message) {
  const env = getEnv();

  if (env === 'test' || env === 'mock') {
    // eslint-disable-next-line no-console
    console.log(message);
  }
}

export function devdebug(...message) {
  const env = getEnv();

  if (env === 'test' || env === 'mock') {
    // eslint-disable-next-line no-console
    console.debug(message);
  }
}
